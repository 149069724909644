#modal_container___dvotb {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: transparent;
  z-index: 999999;
  pointer-events: none;
}

.content___1Mg4t {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  pointer-events: fill;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  overflow: hidden;
  min-width: 54px;
}

.container___2NDzA {
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  position: relative;
  max-height: 300px;
  overflow: auto;
  min-width: 60px;
}

.options___2oAO2 {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: none;
  flex-direction: row;
  padding: 16px;
}
.options___2oAO2 > button {
  flex: 1;
}
.title___1Civ9 {
  text-align: center;
  font-weight: 600;
  height: 46px;
  display: none;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.options___2oAO2 > button:first-child {
  background-color: transparent;
  border: 0;
}

@media only screen and (max-width: 991px) {
  #modal_container___dvotb {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: fill;
  }
  .content___1Mg4t {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    pointer-events: fill;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 0;
    max-height: 100%;
  }
  .title___1Civ9,
  .options___2oAO2 {
    display: flex;
  }

  .container___2NDzA {
    flex-direction: column;
  }
}
